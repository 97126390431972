import { Component, ViewChild } from '@angular/core';
import { RequestForInformation } from '../request-for-information';
import { RequstForInformationQueryFilter, defaultRFIQueryFilters } from '../request-for-information-query';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NoResultsComponent } from '../../no-results/no-results.component';
import { RequstForInformationStatus, RequstForInformationStatusEnumDescriptionService } from '../request-for-information-status';
import { RequestForInformationFormService } from 'src/app/services/request-for-information-form.service';
import { AuthService } from 'src/app/services/auth.service';
import { RequestForInformationFormOptionService } from 'src/app/services/request-for-information-form-option.service';
import { tap } from 'rxjs';
import { Sort } from '@angular/material/sort';

@Component({
	selector: 'app-request-for-information-forms-list',
	templateUrl: './request-for-information-forms-list.component.html',
	styleUrls: ['./request-for-information-forms-list.component.scss']
})
export class RequestForInformationFormsListComponent {
	totalRecords: number = 25;
	rfiForms: RequestForInformation[] = [];
	rfiQueryFilter: RequstForInformationQueryFilter = defaultRFIQueryFilters();

	rfiStatus: string = "";
	treatmentType: any[] = []
	inquiryReceived: any[] = []
	applicationStatus = Object.values(RequstForInformationStatus).filter((f) => !isNaN(Number(f)));
	columnsToDisplay = [
		'UpdatedOnDateTimeUTC', 'Id', 'LastName', 'RequestForInformationStatus'
	];

	pageEvent!: PageEvent;
	@ViewChild('paginator') paginator!: MatPaginator;
	@ViewChild('noResults') noResults!: NoResultsComponent;

	constructor(
		private rfiService: RequestForInformationFormService,
		private rfiOptionService: RequestForInformationFormOptionService,
		public enumDescService: RequstForInformationStatusEnumDescriptionService,
		private authservice: AuthService) {
	}


	ngOnInit(): void {
		this.rfiOptionService.getTreatmentOptions().subscribe((data) => { this.treatmentType = data });
		this.rfiOptionService.getInquiryReceived().subscribe((data) => { this.inquiryReceived = data });
		this.searchWithFilters();
	}

	ngAfterViewInit(): void {
		if (this.paginator && this.paginator.page) {
			this.paginator?.page.pipe(tap(() => this.searchWithFilters())
			).subscribe();
		}
	}

	handlePageEvent(e: PageEvent) {
		this.pageEvent = e;
		this.rfiQueryFilter.PageNumber = e.pageIndex;
		this.rfiQueryFilter.PageSize = e.pageSize;
	}

	setCaseFileStatus(a: number): string {
		let desc = this.enumDescService.getDescription(a);
		return desc;
	}

	searchWithFilters() {
		this.rfiService.getRequestForInformationForms(this.rfiQueryFilter).subscribe({
			next: (data) => {
				this.bindDataAndPaging(data);
			},
			error: (error) => {
				console.log(error, 'Unable to connect to CaseFile API Endpoint');
			},
			complete: () => { }
		});
	}

	private bindDataAndPaging(data: any) {
		let headers = data.headers.get('x-pagination');
		if (headers) {
			let parsedHeaders = JSON.parse(headers);
			this.totalRecords = parsedHeaders.TotalCount;
			this.noResults.setTotal(this.totalRecords);
		}
		this.rfiForms = data.body ? data.body : [];
	}

	resetFilters(): void {
		this.rfiQueryFilter = defaultRFIQueryFilters();
		this.paginator.pageSize = this.rfiQueryFilter.PageSize;
		this.paginator.pageIndex = this.rfiQueryFilter.PageNumber;

		this.searchWithFilters();
	}

	announceSortChange(sortState: Sort) {
		this.rfiQueryFilter.OrderByProperty = sortState.active;
		this.rfiQueryFilter.OrderByDirection = sortState.direction ? sortState.direction : 'desc';

		this.searchWithFilters();
	}
}