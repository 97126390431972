export interface AuthModel {
    userDbId: number,
    token: string,
    expiryUtc: string,
    isLoginSuccessful: boolean,
    mfaLoginResult: MFALoginResult,
    requireMFA: boolean,
    roles: number,
    passwordChangeRequiredDays: number,
    agentName: string,
    username: string
}

export enum MFALoginResult {
    NotAttempted = 0,
    Challenge = 1,
    Failed = 2,
    Successful = 3
}

export function defaultAuthModelValues(): AuthModel {
    return {
        userDbId: 0,
        token: '',
        expiryUtc: '',
        isLoginSuccessful: false,
        mfaLoginResult: MFALoginResult.NotAttempted,
        requireMFA: false,
        roles: 0,
        passwordChangeRequiredDays: 0,
        agentName: '', 
        username: ''
    }
}