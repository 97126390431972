import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BlankCaseFile, CaseFile } from '../../case-file/case-file';
import { CaseFileOptionService } from 'src/app/services/case-file-option.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'app-section4-ra',
	templateUrl: './section4-ra.component.html',
	styleUrls: ['./section4-ra.component.scss'],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class Section4RaComponent implements OnInit, AfterViewInit {
	@Input() caseFile: CaseFile = BlankCaseFile();
	@Input() isDisabled: boolean = false;
	@Input() parentSubmitted: boolean = false;
	@Input() currentMeetingStatusType: number = 0;
	@ViewChild("cancelRAReason") cancellationNotesRA !: NgForm
	appointmentLength: any[] = [];
	raAdditionalInfomeetingStatus: any[] = [];
	meetingFormats: any[] = [];
	meetingAccompaniments: any[] = [];
	providedInterpreterServices: any[] = [];
	providedInterpreterServicesOtherDisabled: boolean = true;
	providedInterpreterServicesShowLanguagePicker: boolean = false;
	interpretationLanguages: any[] = [];
	assistanceProvided: any[] = [];
	referralsMade: any[] = [];
	referralsNotMade: any[] = [];
	restraintOrSeclusions: any[] = [];
	restraintOthersDisabled: boolean = true;
	unmetAccommodationNeeds: any[] = [];
	appointmentConnectionIssues: any[] = [];
	appointmentConnectionIssueOtherDisabled: boolean = true;
	maCheckedItems: any[] = [];
	maOthersDisabled: boolean = true;
	apCheckedItems: any[] = [];
	apOthersDisabled: boolean = true;
	rmOthersDisabled: boolean = true;
	rnmOthersDisabled: boolean = true;
	collectAllTheMeetingInformation: boolean= false;
	hideCancellationReason: boolean = true;

	constructor(
		private caseFileOptionService: CaseFileOptionService
	) { }

	ngOnInit(): void {
		this.caseFileOptionService.getAppointmentLength().subscribe({
			next: (res) => { this.appointmentLength = res },
			error: (err) => console.log('Error:' + err),
		});

		this.caseFileOptionService.getRAAdditionalInfoMeetingStatus().subscribe({
			next: (res) => { this.raAdditionalInfomeetingStatus = res },
			error: (err) => console.log('Error:' + err)
		});

		this.caseFileOptionService.getMeetingFormats().subscribe({
			next: (meetingformats) => { this.meetingFormats = meetingformats },
			error: (err) => { console.log(err) },
			complete: () => { }
		});

		this.caseFileOptionService.getMeetingAccompaniments().subscribe({
			next: (res) => { this.meetingAccompaniments = res },
			error: (err) => console.log('Error:' + err)
		});

		this.caseFileOptionService.getProvidedInterpreterServices().subscribe({
			next: (res) => { this.providedInterpreterServices = res },
			error: (err) => console.log('Error:' + err)
		});

		this.caseFileOptionService.getAssistanceProvided().subscribe({
			next: (res) => { this.assistanceProvided = res },
			error: (err) => console.log('Error:' + err)
		});

		this.caseFileOptionService.getReferralsMade().subscribe({
			next: (res) => { this.referralsMade = res },
			error: (err) => console.log('Error:' + err)
		});

		this.caseFileOptionService.getReferralsNotMade().subscribe({
			next: (res) => { this.referralsNotMade = res },
			error: (err) => console.log('Error:' + err)
		});

		this.caseFileOptionService.getRestraintOrSeclusions().subscribe({
			next: (res) => { this.restraintOrSeclusions = res },
			error: (err) => console.log('Error:' + err)
		});

		this.caseFileOptionService.getUnmetAccommodationNeeds().subscribe({
			next: (res) => { this.unmetAccommodationNeeds = res },
			error: (err) => console.log('Error:' + err)
		});

		this.caseFileOptionService.getAppointmentConnectionIssues().subscribe({
			next: (res) => { this.appointmentConnectionIssues = res },
			error: (err) => console.log('Error:' + err)
		});

		this.caseFileOptionService.getInterpretationLanguage().subscribe({
			next: (res) => { this.interpretationLanguages = res },
			error: (err) => console.log('Error:' + err)
		});

		
	}

	ngAfterViewInit(): void {
		this.rightsAdvisorMeetingStatusChange();
		this.toggleDefaultOptionsOnLoad()
	}

	onMACheckboxChange(item: any): void {
		this.maOthersDisabled = true;
		this.caseFile.meetingAccompanimentsOther = "";

		if (this.isItemChecked(item, this.caseFile.meetingAccompaniments)) {
			this.caseFile.meetingAccompaniments = this.caseFile.meetingAccompaniments.filter((i) => i.id !== item.id);
		} else {
			this.caseFile.meetingAccompaniments.push(item);
		}

		if (this.caseFile.meetingAccompaniments.find((z) => z.meetingAccompanimentsName == "Other")) {
			this.maOthersDisabled = false;
		}
	}

	toggleDefaultOptionsOnLoad(): void {
		if (this.caseFile.providedInterpreterServices.find((z) => z.name == "Interpreter")) {
			this.providedInterpreterServicesShowLanguagePicker = true;
		} else {
			this.providedInterpreterServicesShowLanguagePicker = false;
		}
	}

	onProvidedInterpreterCheckboxChange(item: any): void {
		this.providedInterpreterServicesOtherDisabled = true;
		this.caseFile.providedInterpreterServicesOther = '';

		if (this.isItemChecked(item, this.caseFile.providedInterpreterServices)) {
			this.caseFile.providedInterpreterServices = this.caseFile.providedInterpreterServices.filter((i) => i.id !== item.id);
		} else {
			this.caseFile.providedInterpreterServices.push(item);
		}

		if (this.caseFile.providedInterpreterServices.find((z) => z.name == "Interpreter")) {
			this.providedInterpreterServicesShowLanguagePicker = true;
		} else {
			this.providedInterpreterServicesShowLanguagePicker = false;
		}

		if (this.caseFile.providedInterpreterServices.find((z) => z.name == "Other")) {
			this.providedInterpreterServicesOtherDisabled = false;
		}
	}

	onUnmetAccommodationCheckboxChange(item: any): void {
		if (this.isItemChecked(item, this.caseFile.unmetAccommodationNeeds)) {
			this.caseFile.unmetAccommodationNeeds = this.caseFile.unmetAccommodationNeeds.filter((i) => i.id !== item.id);
		} else {
			this.caseFile.unmetAccommodationNeeds.push(item);
		}
	}

	onAppointmentConnectionCheckboxChange(item: any): void {
		this.appointmentConnectionIssueOtherDisabled = true;
		this.caseFile.appointmentConnectionIssueOther = '';

		if (this.isItemChecked(item, this.caseFile.appointmentConnectionIssues)) {
			this.caseFile.appointmentConnectionIssues = this.caseFile.appointmentConnectionIssues.filter((i) => i.id !== item.id);
		} else {
			this.caseFile.appointmentConnectionIssues.push(item);
		}

		if (this.caseFile.appointmentConnectionIssues.find((z) => z.name.startsWith("Other"))) {
			this.appointmentConnectionIssueOtherDisabled = false;
		}
	}

	onRestraintCheckboxChange(item: any): void {
		this.restraintOthersDisabled = true;
		this.caseFile.restraintOrSeclusionsOther = '';

		if (this.isItemChecked(item, this.caseFile.restraintOrSeclusions)) {
			this.caseFile.restraintOrSeclusions = this.caseFile.restraintOrSeclusions.filter((i) => i !== item);
		} else {
			this.caseFile.restraintOrSeclusions.push(item);
		}

		if (this.caseFile.restraintOrSeclusions.find((z) => z.name.startsWith("Other"))) {
			this.restraintOthersDisabled = false;
		}
	}

	onAPCheckboxChange(item: any): void {
		this.apOthersDisabled = true;
		this.caseFile.assistanceProvidedOther = "";

		if (this.isItemChecked(item, this.caseFile.assistanceProvided)) {
			this.caseFile.assistanceProvided = this.caseFile.assistanceProvided.filter((i) => i !== item);
		} else {
			this.caseFile.assistanceProvided.push(item);
		}

		if (this.caseFile.assistanceProvided.find((z) => z.assistanceProvidedName == "Other")) {
			this.apOthersDisabled = false;
		}
	}

	onRMCheckboxChange(item: any): void {
		this.rmOthersDisabled = true;
		this.caseFile.referralsMadeOther = "";
		if (this.isItemChecked(item, this.caseFile.referralsMade)) {
			this.caseFile.referralsMade = this.caseFile.referralsMade.filter((i) => i !== item);
		} else {
			this.caseFile.referralsMade.push(item);
		}

		if (this.caseFile.referralsMade.find((z) => z.referralsMadeOptions == "Other")) {
			this.rmOthersDisabled = false;
		}
	}

	onRNMCheckboxChange(item: any): void {
		this.rnmOthersDisabled = true;
		if (this.isItemChecked(item, this.caseFile.referralsNotMade)) {
			this.caseFile.referralsNotMade = this.caseFile.referralsNotMade.filter((i) => i !== item);
		} else {
			this.caseFile.referralsNotMade.push(item);
		}

		if (this.caseFile.referralsNotMade.find((z) => z.referralsNotMadeOption == "Other")) {
			this.rnmOthersDisabled = false;
		}
	}

	rightsAdvisorMeetingStatusChange(): void {
		this.currentMeetingStatusType = this.caseFile.raAdditionalInformationMeetingStatusVersionId
		this.toggleMeetingStatusChange()
	}

	toggleMeetingStatusChange(): void {
		// This needs to be set dynamically based on a flag from API, or match on string. Temporary for quick turnaround.
		const statiiThatCollectMoreInfo = [1, 2, 3];
		const cancelledReasonStatus = 7;

		if (statiiThatCollectMoreInfo.includes(this.currentMeetingStatusType)) {
			this.collectAllTheMeetingInformation = true;
		} else {
			this.collectAllTheMeetingInformation = false;
		}

		const statusIsCancelled = this.currentMeetingStatusType == cancelledReasonStatus;
		this.hideCancellationReason = !statusIsCancelled;
	}

	// Change the 1st person format of the in-person option. Should come from the API.
	reformatMeetingName(meetingName: string) {
		if (meetingName.includes('in-person')) {
			return "In Person";
		}

		return meetingName;
	}

	isItemChecked(item: any, selectedItems: any[]): boolean {

		if (selectedItems === undefined)
			return false;

		return selectedItems.some((selectedItem) => selectedItem.id === item.id);
	}

	validateSection4RA():boolean
	{
		let flag = true;
		if(this.caseFile.raAdditionalInformationMeetingStatusVersionId ==null)
		{
			flag=false;
		}
		if(this.collectAllTheMeetingInformation && this.caseFile.appointmentLengthVersionId == null){
			flag = false;	
		}
		if(this.collectAllTheMeetingInformation && this.caseFile.rightsAdvisorAdditionalInformationMeetingFormatVersionId == null)
		{
			flag = false;
		}
		if(this.collectAllTheMeetingInformation && this.caseFile.difficultyAccessingRightsAdvice == null)
		{
			flag=false;
		}
		if(this.collectAllTheMeetingInformation && this.caseFile.desiredAdviceProvided == null)
		{
			flag=false;
		}
		if(this.collectAllTheMeetingInformation && this.caseFile.followUp == null)
		{
			flag=false;
		}
		
		if(!this.hideCancellationReason && this.caseFile.rightsAdvisorReasonForCancellation == '')
		{
			Object.values(this.cancellationNotesRA.controls).forEach(control=>control.markAllAsTouched())
			flag=false;
		}
		return flag;
	}
}
