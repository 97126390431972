import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { topnavService } from 'src/app/services/topnav.service';
import { AuthService } from 'src/app/services/auth.service';
import {
  ApplicationRoles,
  ApplicationRolesEnumDescriptionService,
} from '../users/user-roles';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
  ],
})
export class TopnavComponent implements OnInit {
  dashboardCMHAEnabled: boolean = false;
  dashboardRAEnabled: boolean = false;
  AdminEnabled: boolean = false;
  FormsEnabled: boolean = false;
  token: string = '';
  showUserInfo: boolean = false;
  username: string = '';
  userRole: string = '';
  userlogo: boolean = true;
  profileVisible: boolean = true;
  contentClass: string = 'collapse navbar-collapse';
  isClicked = true;
  dashboardLink = '';

  constructor(
    private route: Router,
    private _topnavservice: topnavService,
    private _authService: AuthService,
    private _applicationroleenumservice: ApplicationRolesEnumDescriptionService
  ) { }

  navigate(url: string) {
    this.route.navigateByUrl(url);
  }

  logout(): void {
    this._authService.logout();
    this.route.navigateByUrl('/login');
  }

  changePassword(): void {
    this.route.navigate(['/profile']);
  }

  changetoggle() {
    if (this.isClicked) {
      this.contentClass = 'collapse navbar-collapse show responsive-panel';
      this.isClicked = false;
    } else {
      this.contentClass = 'collapse navbar-collapse';
      this.isClicked = true;
    }
  }

  ngOnInit(): void {
    this.token = this._authService.getAuthToken();
    if (this.token == null) {
      return;
    }

    this._topnavservice.getTopNav(this.token).subscribe({
      next: (data) => {
        this.showUserInfo = true;
        this.dashboardCMHAEnabled = data.dashboardCMHAEnabled;
        this.dashboardRAEnabled = data.dashboardRAEnabled;
        this.AdminEnabled = data.adminEnabled;
        this.FormsEnabled = data.formsEnabled;

      },
      error: (err) => {
        console.log(err);
      },
      complete: () => { },
    });

    this.username = this._authService.getUserLoginData().agentName;
    let role = this._authService.getRole();

    switch (role) {
      case ApplicationRoles.DESIGNATED_FACILITY:
        let user = this.username.split(':');
        this.username = user[0];
        this.userRole = user[1];
        this.userlogo = false;
        this.profileVisible = false;

        break;

      default:
        this.userRole = this._applicationroleenumservice.getDescription(
          this._authService.getRole()
        );
        this.userlogo = true;
        this.profileVisible = true;

        break;
    }

    switch (role) {
      case ApplicationRoles.DESIGNATED_FACILITY:
        this.dashboardLink = '/login';
        break;
      case ApplicationRoles.CMHA_ADMIN:
        this.dashboardLink = '/dashboard-cmha';
        break;
      case ApplicationRoles.CMHA_INTAKE:
        this.dashboardLink = '/dashboard-cmha';
        break;
      case ApplicationRoles.RIGHTS_ADVISOR:
        this.dashboardLink = '/dashboard-ra';
        break;
      default:
        this.dashboardLink = '/login';
    }
  }
}
