<app-topnav></app-topnav>
<div class="page-heading">
    <div class="container">
        <h1>Portal Login</h1>
    </div>
  </div>
  <div class="container">
    <div class="content">
        <div class="clear"></div>
        <div class="clear"></div>
        <div class="login-heading">Choose Account Type</div>
        <span class="login-subheading">Login under the appropriate account to access the relevant resources</span>
       
        <div class="login-components">
            <div>
                <span>
                    Mental Health Facility
                    <br>
                    <h4>Access Request For Rights Advice Form</h4>
                    <button routerLink="/df-login/0">Login</button>
                </span>
            </div>
            <div>
                <span>
                    Intake Coordinator
                    <br>
                    <h4>Access Portal administration features</h4>
                    <button routerLink="/cmha-login" >Login</button>
                </span>
            </div>
            <div>
                <span>
                    Rights Advisor
                    <br>
                    <h4>Access rights advice <br>portal</h4>
                    <button routerLink="/ra-login" >Login</button>
                </span>
            </div>
        </div>  
    </div>
  </div>
  <app-footer></app-footer>