import { Component, Input } from '@angular/core';
import { CaseFileAuditlog, defaultCaseFileAuditList } from '../case-file-audit-log';
import { CaseFileService } from 'src/app/services/case-file.service';
import { BlankCaseFile, CaseFile } from '../case-file';
import { AuditEventTypeEnumDescriptionService } from './audti-event-type';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-case-file-audit-log-list',
  templateUrl: './case-file-audit-log-list.component.html',
  styleUrls: ['./case-file-audit-log-list.component.scss']
})

export class CaseFileAuditLogListComponent {
  @Input() caseFile: CaseFile = BlankCaseFile();
  auditlogVisible: boolean = false;
  caseFileAuditLogs: CaseFileAuditlog[] = [];
  columnsToDisplay = ['CaseFileId', 'Date', 'Event', 'Details']

  constructor(private _casefileservice: CaseFileService, public enumdescservice: AuditEventTypeEnumDescriptionService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this._casefileservice.getCaseFileAuditLog(this.caseFile.id).subscribe((data) => {
      this.caseFileAuditLogs = data
      this.auditlogVisible = this.caseFileAuditLogs.length > 0;
    });
  }

  checkIfValidDate(inputString: string): string {
    const date = new Date(inputString);
    
    // Check if the date is valid and inputString does not contain non-numeric characters
    if (!isNaN(date.getTime()) && /^\d/.test(inputString)) {
      return this.datePipe.transform(inputString, 'dd/MM/yyyy hh:mm')?.toString()!
    }
    else {
      return inputString;
    }
  }
}
